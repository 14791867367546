<template>
  <div class="contact">
    <div class="contact__content">
      <!-- <header>{{ header }}</header> -->

      <section class="header">
        <div class="name">&nbsp;</div>
        <div class="information">{{ header }}</div>
      </section>

      <section class="no-bottom">
        <div class="name">{{ addressName }}</div>
        <div class="information">{{ address }}</div>
      </section>

      <section class="no-top">
        <div class="name"></div>
        <div class="information">
          <p class="address" v-for="(paragraph, index) in paragraphs" :key="index">
            {{ paragraph }}
          </p>
        </div>
      </section>

      <section>
        <div class="name">{{ telephoneName }}</div>
        <div class="information">{{ telephone }}</div>
      </section>

      <section>
        <div class="name">{{ emailName }}</div>
        <div class="information">
          <a href="mailto:eepublic@emsd.gov.hk">{{ email }}</a>
        </div>
      </section>
      <!-- <div class="parahraph" v-for="(paragraph, index) in paragraphs" :key="index">
          {{ paragraph }}
        </div> -->
    </div>
  </div>
</template>

<script>
import { content } from "./content";
import { mapGetters } from "vuex";
export default {
  name: "Contact",
  computed: {
    ...mapGetters({
      language: "localize/language"
    }),
    header: function() {
      return content[this.language].header;
    },
    paragraphs: function() {
      return content[this.language].paragraph;
    },
    addressName: function() {
      return content[this.language].addressName;
    },
    address: function() {
      return content[this.language].address;
    },
    telephoneName: function() {
      return content[this.language].telephoneName;
    },
    telephone: function() {
      return content[this.language].telephone;
    },
    emailName: function() {
      return content[this.language].emailName;
    },
    email: function() {
      return content[this.language].email;
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less">
.contact {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    width: 1200px;
    height: 100%;

    display: flex;
    overflow: auto;
    flex-direction: column;
    background-color: #fffef6;

    .header {
      margin-top: 50px;
      margin-bottom: 65px;
      padding-inline-start: 18px;
      padding-inline-end: 18px;
      font-size: 3em;
      color: #fdb814;
      text-align: center;
      font-weight: bold;

      .information {
        text-align: left;
        padding-left: 8px;
      }
    }

    section {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 1.6em;
      color: #303030;

      &.no-bottom {
        margin-bottom: 0;
      }

      &.no-top {
        margin-top: 0;
      }

      .name {
        padding: 0 30px;
        width: 30%;
        text-align: right;
      }

      .information {
        flex: 1;
        padding: 0 30px;
      }
    }
  }
}

@media screen and (max-width: 812px) {
  .contact {
    &__content {
      .header {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 2em;
      }

      section {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1em;
        color: #303030;

        &.no-bottom {
          margin: 0;
        }

        &.no-top {
          margin: 0;
        }

        .name {
          padding: 0 30px;
          width: 30%;
          text-align: right;
        }

        .information {
          flex: 1;
          padding: 0 30px;
          p {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 540px) {
@media screen and (max-width: 667px) {
  .contact {
    &__content {
      width: 100%;
      justify-content: center;
      .header {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 2em;
        color: #fdb814;
      }

      section {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 0.8em;
        color: #303030;

        .name {
          padding: 0 5px;
          min-width: 80px;
          text-align: right;
        }

        .information {
          flex: 1;
          padding: 0 15px;
        }
      }
    }
  }
}
</style>
