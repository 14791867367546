export const content = {
  "CN-ZH": {
    header: "联络我们",
    addressName: "地址",
    address: "能源效益事务处",
    telephoneName: "公众咨询热线",
    telephone: "1823",
    emailName: "电邮",
    email: "eepublic@emsd.gov.hk",
    paragraph: [`机电工程署总部大楼`, `香港九龙启成街 3 号`]
  },
  "CN-TW": {
    header: "聯絡我們",
    addressName: "地址",
    address: "能源效益事務處",
    telephoneName: "公眾諮詢熱線",
    telephone: "1823",
    emailName: "電郵",
    email: "eepublic@emsd.gov.hk",
    paragraph: [`機電工程署總部大樓`, `香港九龍啟成街 3 號 `]
  },
  ENG: {
    header: "Contact Us",
    addressName: "Address",
    address: "Energy Efficiency Office",
    telephoneName: "Public Enquiry Hotline",
    telephone: "1823",
    emailName: "Email",
    email: "eepublic@emsd.gov.hk",
    paragraph: [
      `Electrical and Mechanical Services Department`,
      `3 Kai Shing Street, Kowloon, Hong Kong`
    ]
  }
};
